










import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Logout extends Vue {
  mounted() {
    this.$store.dispatch('Auth/logout', {}, { root: true });
    this.$router.push('/');
  }
}
